var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"audit-log"}},[_vm._m(0),_c('div',[_c('v-row',{staticClass:"mt-1 mb-3",staticStyle:{"align-items":"center"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"id":"startDate","label":"Start date","outlined":"","hide-details":"","dense":"","type":"date"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"id":"endDate","label":"End date","outlined":"","hide-details":"","dense":"","type":"date"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticStyle:{"min-width":"100%"},attrs:{"color":"primary"},on:{"click":_vm.fetchData}},[_vm._v("Go")])],1)],1)],1),(_vm.loading)?_c('div',{staticClass:"fill-height d-flex justify-center align-center"},[_c('Loader')],1):_c('div',[(_vm.auditLogs)?_c('div',[_c('v-card',[_c('div',{staticClass:"px-3"},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"append-icon":"","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.auditLogsSearch),callback:function ($$v) {_vm.auditLogsSearch=$$v},expression:"auditLogsSearch"}})],1)],1)],1),_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":[
                  {
                    text: 'Timestamp',
                    value: 'timestamp',
                    sortable: true,
                  },
                  {
                    text: 'Type',
                    value: 'type',
                    sortable: true,
                  },
                  {
                    text: 'ID',
                    value: 'id',
                    sortable: true,
                  },
                  {
                    text: 'User',
                    value: 'user',
                    sortable: true,
                  },
                  {
                    text: 'Message',
                    value: 'message',
                    sortable: true,
                  } ],"items":_vm.auditLogsData,"custom-sort":_vm.customSort,"search":_vm.auditLogsSearch,"sort-by":_vm.auditLogsSortBy,"sort-desc":_vm.auditLogsSortDesc,"must-sort":true},on:{"update:sortBy":function($event){_vm.auditLogsSortBy=$event},"update:sort-by":function($event){_vm.auditLogsSortBy=$event},"update:sortDesc":function($event){_vm.auditLogsSortDesc=$event},"update:sort-desc":function($event){_vm.auditLogsSortDesc=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.timestamp))]),_c('td',[_vm._v(_vm._s(item.type))]),_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.user))]),_c('td',[_vm._v(_vm._s(item.message_formatted))])])]}}],null,false,2250156799)})],1)],1)],1)])],1):_c('div',[_c('span',{staticClass:"font-weight-semibold text--primary"},[_vm._v(" No Audit Logs available ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title px-4 mb-4"},[_c('h2',[_c('span',{staticClass:"me-2"},[_vm._v("Audit Logs")])])])}]

export { render, staticRenderFns }